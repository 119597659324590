import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import './index.css';

const IndexPage = () => (
  <Layout>
    <SEO
      title="Lewis Loofis"
      description="Lewis Loofis - Front End Developer and Finance Enthusiast"
    />
    <div className="hero">
      <h1 data-text="Lewis Loofis">Lewis Loofis</h1>
      <p>Front End Developer</p>
      <a href="mailto:lewisloofis@yahoo.com">Send me Business Inquiries</a>
    </div>
  </Layout>
)

export default IndexPage
